/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { styled, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Text } from 'mpharma-i18n';
import './App.css';
import ErrorBoundary from './common/components/ErrorBoundary';
import Home from './common/components/Home';
import Layout from './common/components/Layout';
import Theme from './common/Theme';
import { useLocalStorage } from 'react-use';
import useAppMountStatus from './hooks/useAppMountStatus';
import useOfflineStatus from './hooks/useOfflineStatus';
import useSalesHistoryDownloadsBroadcastChannel from './hooks/useSalesHistoryDownloadsBroadcastChannel';
import useInventoryDownloadsBroadcastChannel from './hooks/useInventoryLevelDownloadsBroadcastChannel';
import useLostSalesCountChannel from './hooks/useLostSalesCountChannel';
import AuthWorker from './workers/auth.worker.js';
import ResourceDownloadWorker from './workers/download.worker.js';
import { APP_ROLES } from './helpers/roles';
import { API_ROOT, BASE_NAME, INTERCOM_APP_ID, links } from './helpers/constants';
import { getCookie } from './helpers/utils';
import { updateFacilityUser } from './db/utils';
import { normalizeFacilities } from './helpers/normalizers';
import { get } from './admin/api';
import { clearBloomDB, requestInventoryLevelDownloadFile, requestSalesHistoryFile } from './common/state/auth/reducers';
import LogoutScreen from './common/components/LogoutScreen';
import MixpanelProvider, { useMixpanel } from './common/components/Mixpanel/Provider';
import OfflinePinModal from './common/components/OfflinePinModal';
import usePriceUploadProgressBroadcastChannel from './hooks/usePriceUploadProgressBroadcastChannel';
import useDeliveryNoteUploadProgressBroadcastChannel from './hooks/useDeliveryNoteUploadProgressBroadcastChannel';
import useStockUploadProgressBroadcastChannel from './hooks/useStockUploadProgressBroadcastChannel';
import useCustomProductUploadProgressBroadcastChannel from './hooks/useCustomProductUploadProgressBroadcastChannel';
import BloomNotifications from './common/components/Notifications/BloomNotifications.js';

const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`,
  loading: `${PREFIX}-loading`,
  primary: `${PREFIX}-primary`,
  login: `${PREFIX}-login`
};

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    height: '100%',
    zIndex: '1000000',
    background: 'white',
    paddingTop: '50px',
    borderLeft: '1px solid',
    borderLeftColor: '#eee',
    marginTop: 'unset'
  },

  [`& .${classes.primary}`]: {
    color: '#FF5001'
  },

  [`& .${classes.login}`]: {
    marginTop: 20,
    fontSize: 16
  }
}));

const authWorker = new AuthWorker();
const resourceDownloadWorker = new ResourceDownloadWorker();

const idToken = getCookie('idToken');

function App() {
  const { online } = useOfflineStatus();
  const { appLoading, errorMessage } = useAppMountStatus();
  const [loading, setLoading] = useState(false);
  const [salesHistoryLink, setSalesHistoryLink] = useState(null);
  const [inventoryLevelDownloadLink, setInventoryLevelDownloadLink] = useState(null);
  const [salesLinkReqIntervalId, setSalesLinkReqIntervalId] = useState(null);
  const [inventoryLevelDownloadLinkReqIntervalId, setInventoryLevelDownloadLinkReqIntervalId] = useState(null);
  const [openedNotificationDrawer, setOpenedNotificationDrawer] = useState(false);
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [openExpiringSoonNotification, setOpenExpiringSoonNotification] = useState(false);
  const [openExpiredProductsNotification, setOpenExpiredProductsNotification] = useState(false);
  const [openPriceUpdateModal, setOpenPriceUpdateModal] = useState(false);
  const [salesHistoryBroadChanId, setSalesHistoryBroadChanId] = useSalesHistoryDownloadsBroadcastChannel();
  const [
    inventoryLevelDownloadBroadChanId,
    setInventoryLevelDownloadBroadChanId
  ] = useInventoryDownloadsBroadcastChannel();
  const [unsyncedLostSaleCount, setUnsyncedLostSaleCount] = useLostSalesCountChannel();
  const [showFIReminder, setshowFIReminder] = useState(false);
  const [showWrappedNotification, setshowWrappedNotification] = useState(false);

  const [newMCAFeedback, setNewMCAFeedback] = useState(false);
  const [showMCAFeedbackDialog, setShowMCAFeedbackDialog] = useState(false);
  const [user, setUser] = useLocalStorage('bloom:user');
  const [configs, setConfigs] = useLocalStorage('bloom:configs');
  const hasTriggeredRefreshToRemountMicroApps = useRef(user && configs);
  const [facility, setFacility] = useLocalStorage('bloom:facility');
  const [salesLocalStorageHistoryObjectId, setSalesLocalStorageHistoryObjectId] = useLocalStorage(
    'salesHistoryObjectId',
    ''
  );
  const [inventoryLevelDownloadObjectId, setInventoryLevelDownloadObjectId] = useLocalStorage(
    'inventoryLevelDownloadTrackerId'
  );
  const [, setOfflineLogout] = useLocalStorage('bloom:offlineLoggedOut', false);
  const {
    priceUploadProgress,
    setPriceUploadProgress,
    priceErrorCount,
    setPriceErrorCount,
    pricePageSwitched,
    setPricePageSwitched,
    priceUploadProcessingStatus,
    setPriceUploadProcessingStatus
  } = usePriceUploadProgressBroadcastChannel();

  const [
    deliveryNoteUploadProgress,
    setDeliveryNoteUploadProgress,
    deliveryNoteErrorCount,
    setDeliveryNoteErrorCount,
    deliveryNotePageSwitched,
    setDeliveryNotePageSwitched
  ] = useDeliveryNoteUploadProgressBroadcastChannel();
  const [
    stockUploadProgress,
    setStockUploadProgress,
    stockErrorCount,
    setStockErrorCount,
    stockPageSwitched,
    setStockPageSwitched
  ] = useStockUploadProgressBroadcastChannel();
  const [
    customProductUploadProgress,
    setCustomProductUploadProgress,
    customProductErrorCount,
    setCustomProductErrorCount,
    customProductPageSwitched,
    setCustomProductPageSwitched
  ] = useCustomProductUploadProgressBroadcastChannel();
  const contractType = user && facility?.entities?.facilities[user?.facility_id]?.contract_type;

  const nextShowDate = localStorage.getItem('bloom:fiMessageNextShowDate');

  const facilityObject = facility && user && facility.entities && facility.entities.facilities[user.facility_id];

  const previousUserFacilityID = localStorage.getItem('bloom:currentUserFacilityId');

  useEffect(() => {
    setPriceUploadProgress(priceUploadProgress);
  }, [priceUploadProgress]);

  useEffect(() => {
    setPriceUploadProcessingStatus(priceUploadProcessingStatus);
  }, [priceUploadProcessingStatus]);

  useEffect(() => {
    setDeliveryNoteUploadProgress(deliveryNoteUploadProgress);
  }, [deliveryNoteUploadProgress]);

  useEffect(() => {
    setStockUploadProgress(stockUploadProgress);
  }, [stockUploadProgress]);

  useEffect(() => {
    setCustomProductUploadProgress(customProductUploadProgress);
  }, [customProductUploadProgress]);

  useEffect(() => {
    setPriceErrorCount(priceErrorCount);
  }, [priceErrorCount]);

  useEffect(() => {
    setDeliveryNoteErrorCount(deliveryNoteErrorCount);
  }, [deliveryNoteErrorCount]);

  useEffect(() => {
    setCustomProductErrorCount(customProductErrorCount);
  }, [customProductErrorCount]);

  useEffect(() => {
    setStockErrorCount(stockErrorCount);
  }, [stockErrorCount]);

  useEffect(() => {
    setPricePageSwitched(pricePageSwitched);
  }, [pricePageSwitched]);

  useEffect(() => {
    setDeliveryNotePageSwitched(deliveryNotePageSwitched);
  }, [deliveryNotePageSwitched]);

  useEffect(() => {
    setStockPageSwitched(stockPageSwitched);
  }, [stockPageSwitched]);

  useEffect(() => {
    setCustomProductPageSwitched(customProductPageSwitched);
  }, [customProductPageSwitched]);

  useEffect(() => {
    setOpenedNotificationDrawer(false);
  }, []);
  useEffect(() => {
    if (salesLocalStorageHistoryObjectId) {
      requestSalesHistoryLink(salesLocalStorageHistoryObjectId);
    }
  }, [salesLocalStorageHistoryObjectId]);

  useEffect(() => {
    if (inventoryLevelDownloadObjectId) {
      requestInventoryLevelDownloadLink(inventoryLevelDownloadObjectId);
    }
  }, [inventoryLevelDownloadObjectId]);

  useEffect(() => {
    if (salesHistoryBroadChanId) {
      requestSalesHistoryLink(salesHistoryBroadChanId);
    }
  }, [salesHistoryBroadChanId]);

  useEffect(() => {
    if (inventoryLevelDownloadBroadChanId) {
      requestInventoryLevelDownloadLink(inventoryLevelDownloadBroadChanId);
    }
  }, [inventoryLevelDownloadBroadChanId]);

  useEffect(() => {
    if (inventoryLevelDownloadLink) {
      clearInterval(inventoryLevelDownloadLinkReqIntervalId);
      setInventoryLevelDownloadLinkReqIntervalId(null);
    }
  }, [inventoryLevelDownloadLink, inventoryLevelDownloadLinkReqIntervalId]);

  useEffect(() => {
    if (salesHistoryLink) {
      clearInterval(salesLinkReqIntervalId);
      setSalesLinkReqIntervalId(null);
    }
  }, [salesHistoryLink, salesLinkReqIntervalId]);

  const requestSalesHistoryLink = requestId => {
    setSalesLinkReqIntervalId(
      setInterval(() => {
        requestSalesHistoryFile(requestId).then(results => {
          if (results?.s3_download_link) {
            if (!salesHistoryLink || salesHistoryLink !== results.s3_download_link) {
              setSalesHistoryLink(results.s3_download_link);
            }
          }
        });
      }, 3000)
    );
  };

  const requestInventoryLevelDownloadLink = requestId => {
    setInventoryLevelDownloadLinkReqIntervalId(
      setInterval(() => {
        requestInventoryLevelDownloadFile(requestId).then(results => {
          if (results?.s3_download_link) {
            if (!inventoryLevelDownloadLink || inventoryLevelDownloadLink !== results.s3_download_link) {
              setInventoryLevelDownloadLink(results.s3_download_link);
            }
          }
        });
      }, 3000)
    );
  };

  const cancelSalesHistoryDownloadRequest = () => {
    setSalesHistoryLink('');
    setSalesLocalStorageHistoryObjectId(null);
    setSalesHistoryBroadChanId(null);
    setSalesLinkReqIntervalId(null);
    setOpenedNotificationDrawer(false);
  };

  const cancelInventoryLevelDownloadRequest = () => {
    setInventoryLevelDownloadLink('');
    setInventoryLevelDownloadObjectId(null);
    setInventoryLevelDownloadBroadChanId(null);
    setInventoryLevelDownloadLinkReqIntervalId(null);
    setOpenedNotificationDrawer(false);
  };

  const endInventoryLevelDownloadBroadChannnel = () => {
    setInventoryLevelDownloadBroadChanId(null);
  };

  authWorker.onmessage = e => {
    if (e.data.sessionExpired === true && online) {
      localStorage.removeItem('bloom:user');
      setTimeout(() => {
        setUser(undefined);
        setConfigs(undefined);
      }, 500);
    } else if (e.data.sessionExpired === false && !user && online) {
      getUserAndConfigData();
    }
  };

  useEffect(() => {
    if (unsyncedLostSaleCount) {
      setUnsyncedLostSaleCount(unsyncedLostSaleCount);
    }
  }, [unsyncedLostSaleCount]);

  async function getUserAndConfigData() {
    setLoading(true);
    try {
      const [userResponse, configResponse] = await Promise.all(links.map(link => get(`${API_ROOT}/${link.url}`)));
      setUser(userResponse);
      setConfigs(configResponse);
      setOfflineLogout(false);
      localStorage.setItem('redirected', 'no');
      hasTriggeredRefreshToRemountMicroApps.current = false;
      if (!userResponse.is_staff) {
        const { content } = await get(`${API_ROOT}/facilities-bff/general/facilities/${userResponse.facility_id}/`);
        setFacility(normalizeFacilities(content));
      }

      await updateFacilityUser(userResponse.id, userResponse);
    } catch (e) {
      console.error('An error occurred', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (online && (!configs || !user || redirected === 'yes')) {
      getUserAndConfigData();
    }
  }, []);

  function clearStorageIfFacilityChanged() {
    if (online && !user.is_staff) {
      if (previousUserFacilityID && previousUserFacilityID !== user.facility_id) {
        clearBloomDB(user.facility_id);
      }
    }
  }

  function terminateWorkerIfAdmin() {
    if (user?.scopes?.includes(APP_ROLES.admin)) {
      resourceDownloadWorker.terminate();
    }
  }

  function postMessageToWorkers() {
    resourceDownloadWorker.postMessage({
      cmd: 'ready',
      id: user.facility_id,
      idToken: getCookie('idToken'),
      facilityName: facilityObject?.name
    });
    authWorker.postMessage({ cmd: 'ready', idToken: getCookie('idToken') });
  }

  useEffect(() => {
    if (user) {
      clearStorageIfFacilityChanged();
      terminateWorkerIfAdmin();
      postMessageToWorkers();
    }
  }, [user, previousUserFacilityID]);

  /*###################################################*/
  /*############### Global Catches        #############*/
  /*###################################################*/
  // Global catch for blurring keyboard on pressing 'Enter'
  useEffect(() => {
    function handleEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    }

    document.addEventListener('keyup', handleEnter, false);

    return function cleanup() {
      document.removeEventListener('keyup', handleEnter, false);
    };
  });

  useEffect(() => {
    if (!loading && user && configs && !hasTriggeredRefreshToRemountMicroApps.current) {
      window.location.reload();
      hasTriggeredRefreshToRemountMicroApps.current = true;
    }
  }, [user, configs, loading]);

  useEffect(() => {
    if (user && configs) {
      Sentry.configureScope(function(scope) {
        scope.setUser({
          id: user.id
        });
      });
    }
  }, [user, configs]);

  function onTryAgain() {
    window.location.reload();
  }

  const mixpanel = useMixpanel();

  useEffect(() => {
    const checkCacheCleared = () => {
      if (!user && !configs) {
        if (mixpanel) {
          mixpanel.track('Cache Cleared', {
            cache_cleared: true
          });
        }
      }
    };

    window.addEventListener('storage', checkCacheCleared);

    return () => {
      window.removeEventListener('storage', checkCacheCleared);
    };
  }, [user, configs]);

  useEffect(() => {
    if (!nextShowDate) {
      setshowFIReminder(true);
    } else {
      const today = new Date();
      if (today > new Date(JSON.parse(nextShowDate))) {
        setshowFIReminder(true);
      } else {
        setshowFIReminder(false);
      }
    }
  }, [nextShowDate]);

  const updateNextFIReminderDate = () => {
    const duration = 7; //Show reminder every 7 days after dismissal.
    const showNext = new Date().setDate(new Date().getDate() + duration);
    localStorage.setItem('bloom:fiMessageNextShowDate', showNext);
  };

  const hideWrappedNotification = () => {
    setshowWrappedNotification(false);
    localStorage.setItem('bloom:fi-hasSeenWrappedNotification', true);
  };

  return (
    <ErrorBoundary>
      <Root>
        {!loading && <OfflinePinModal />}
        {loading && (
          <div className={classes.root}>
            <CircularProgress size={50} classes={{ colorPrimary: classes.primary }} />
            <br />
            <p>
              <Text i18nKey="home.initializeApp">Initializing App</Text>
            </p>
          </div>
        )}
        {!idToken && !loading && <LogoutScreen onSignout={onTryAgain} />}
        {!loading && user && configs && (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Theme}>
              <MixpanelProvider>
                <Router basename={`${BASE_NAME}`}>
                  <IntercomProvider appId={INTERCOM_APP_ID}>
                    <Route
                      path="/:path(home|)"
                      render={props => <Home history={props.history} user={user} contractType={contractType} />}
                    />
                    <Route
                      path="/home"
                      exact
                      render={() => {
                        return <Redirect to="/" />;
                      }}
                    />

                    <Switch>
                      <Route
                        path="/account"
                        render={({ location }) => {
                          //shape of deconstructed props ==>  { history, location, match, staticContext }
                          //catching all direct calls to '/account', prefixing them with '/inventory' and redirecting to the new route
                          return <Redirect to={`/inventory${location.pathname}`} />;
                        }}
                      />

                      <Route
                        path="/bloom/:path"
                        render={({ match }) => {
                          return <Redirect to={`/${match.params.path}`} />;
                        }}
                      />
                      <Route
                        path="/bloom"
                        render={() => {
                          return <Redirect to="/" />;
                        }}
                      />

                      {/* NOTE: Adding a couple of /analytics routes to redirect to /facility-insights before the route is removed */}
                      <Route
                        path="/analytics/reports/:path"
                        render={({ match }) => {
                          return <Redirect to={`/facility-insights/reports/${match.params.path}`} />;
                        }}
                      />
                      <Route
                        path="/analytics/:path"
                        render={({ match }) => {
                          return <Redirect to={`/facility-insights/${match.params.path}`} />;
                        }}
                      />

                      <Route
                        path="/analytics"
                        render={() => {
                          return <Redirect to="/facility-insights/overview" />;
                        }}
                      />
                      {/*End /analytics redirects */}

                      <Route
                        path="*"
                        render={props => (
                          <Layout
                            isAdmin={user?.is_staff}
                            setShowNotificationDrawer={setShowNotificationDrawer}
                            showNotificationDrawer={showNotificationDrawer}
                            resourceDownloadWorker={resourceDownloadWorker}
                            history={props.history}
                            cancelSalesHistoryDownloadRequest={cancelSalesHistoryDownloadRequest}
                            cancelInventoryLevelDownloadRequest={cancelInventoryLevelDownloadRequest}
                            salesLinkReqIntervalId={salesLinkReqIntervalId}
                            salesHistoryLink={salesHistoryLink}
                            openedNotificationDrawer={openedNotificationDrawer}
                            setOpenedNotificationDrawer={setOpenedNotificationDrawer}
                            salesHistoryBroadChanId={salesHistoryBroadChanId}
                            salesLocalStorageHistoryObjectId={salesLocalStorageHistoryObjectId}
                            inventoryLevelDownloadBroadChanId={inventoryLevelDownloadBroadChanId}
                            inventoryLevelDownloadObjectId={inventoryLevelDownloadObjectId}
                            inventoryLevelDownloadLink={inventoryLevelDownloadLink}
                            inventoryLevelDownloadLinkReqIntervalId={inventoryLevelDownloadLinkReqIntervalId}
                            endInventoryLevelDownloadBroadChannnel={endInventoryLevelDownloadBroadChannnel}
                            unsyncedLostSaleCount={unsyncedLostSaleCount}
                            location={props.location.pathname}
                            openExpiringSoonNotification={openExpiringSoonNotification}
                            openExpiredProductsNotification={openExpiredProductsNotification}
                            openPriceUpdateModal={openPriceUpdateModal}
                            showFIReminder={showFIReminder}
                            updateNextFIReminderDate={updateNextFIReminderDate}
                            setshowFIReminder={setshowFIReminder}
                            showWrappedNotification={showWrappedNotification}
                            setshowWrappedNotification={setshowWrappedNotification}
                            hideWrappedNotification={hideWrappedNotification}
                            newMCAFeedback={newMCAFeedback}
                            showMCAFeedbackDialog={showMCAFeedbackDialog}
                            setShowMCAFeedbackDialog={setShowMCAFeedbackDialog}
                            fileUploadsProgress={{
                              price: {
                                progress: priceUploadProgress,
                                errorCount: priceErrorCount,
                                pageSwitched: pricePageSwitched,
                                processingStatus: priceUploadProcessingStatus
                              },
                              deliveryNote: {
                                progress: deliveryNoteUploadProgress,
                                errorCount: deliveryNoteErrorCount,
                                pageSwitched: deliveryNotePageSwitched
                              },
                              stock: {
                                progress: stockUploadProgress,
                                errorCount: stockErrorCount,
                                pageSwitched: stockPageSwitched
                              },
                              customProduct: {
                                progress: customProductUploadProgress,
                                errorCount: customProductErrorCount,
                                pageSwitched: customProductPageSwitched
                              }
                            }}
                          >
                            {appLoading && (
                              <div className={classes.loading}>
                                <CircularProgress size={50} classes={{ colorPrimary: classes.primary }} />
                                <br />
                                <p>
                                  <Text i18nKey="home.loading">Loading App</Text>
                                </p>
                              </div>
                            )}

                            {Boolean(errorMessage) && (
                              <div className={classes.loading}>
                                <p>
                                  <Text i18nKey={errorMessage} />
                                </p>
                              </div>
                            )}

                            <div id="inventory" />
                            <div id="pos" />
                            <div id="orders" />
                            <div id="facility-insights" />
                            <div
                              id="lab-test"
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            />
                            <div id="health-services" />
                            <BloomNotifications
                              showFIReminder={showFIReminder}
                              updateNextFIReminderDate={updateNextFIReminderDate}
                              setshowFIReminder={setshowFIReminder}
                              showWrappedNotification={showWrappedNotification}
                              hideWrappedNotification={hideWrappedNotification}
                              newMCAFeedback={newMCAFeedback}
                              setShowMCAFeedbackDialog={setShowMCAFeedbackDialog}
                              setNewMCAFeedback={setNewMCAFeedback}
                              showMCAFeedbackDialog={showMCAFeedbackDialog}
                              setOpenExpiredProductsNotification={setOpenExpiredProductsNotification}
                              openExpiredProductsNotification={openExpiredProductsNotification}
                              openExpiringSoonNotification={openExpiringSoonNotification}
                              setOpenExpiringSoonNotification={setOpenExpiringSoonNotification}
                              resourceDownloadWorker={resourceDownloadWorker}
                              setOpenPriceUpdateModal={setOpenPriceUpdateModal}
                              setShowNotificationDrawer={setShowNotificationDrawer}
                              setOpenedNotificationDrawer={setOpenedNotificationDrawer}
                            />
                          </Layout>
                        )}
                      />
                    </Switch>
                  </IntercomProvider>
                </Router>
              </MixpanelProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        )}
      </Root>
    </ErrorBoundary>
  );
}

export default App;
